<template>
  <v-container fluid class="pa-0">

    <!-- App bar -->
    <v-app-bar height="30" dark color="primary" flat style="user-select: none">
      <div class="mx-auto" style="font-size: 20px">Mnemonist Software</div>
    </v-app-bar>

    <!-- Header -->
    <Header :title="$t('events.words')" :memo="this.phase === 'recall' ? time : -1" ref="header"/>

    <!-- Pagination -->
    <Pagination :page="page" :pages="pages" :on-page-change="onPageChange"/>

    <!-- Game: memo -->
    <div id="memo" v-if="phase === 'memo'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in memoCols[page - 1]" :key="page + '-' + col.number"
           class="memo-col d-flex flex-column flex-nowrap justify-start align-center">
        <div v-for="word in col.words" :key="word.id"
             class="memo-word d-flex flex-row flex-nowrap justify-center align-center">
          <div class="memo-word-number">{{ word.number }}.</div>
          <div class="memo-word-value" :style="{'background-color': highlight.includes(word.id) ? '#89bbee' : ''}"
               :class="{'highlight': highlight.includes(word.id)}">
            {{ word.value }}
          </div>
        </div>
      </div>
    </div>

    <!-- Game: recall -->
    <div id="recall" v-if="phase === 'recall'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in recallCols[page - 1]" :key="page + '-' + col.number"
           class="recall-col d-flex flex-column flex-nowrap justify-start align-center">
        <div v-for="word in col.words" :key="word.id"
             class="recall-word d-flex flex-row flex-nowrap justify-center align-center"
             :style="{'background-color': highlight.includes(word.id) ? '#89bbee' : ''}">
          <div class="recall-word-number">{{ word.number }}.</div>
          <input type="text" class="recall-word-value-text-field" :id="'recall-input-' + word.id"
                 v-model="recall[word.id]" @click="clickedRecallValue(word.id)"/>
        </div>
      </div>
    </div>

    <!-- Game: result -->
    <div id="result" v-if="phase === 'result'" class="d-flex flex-row flex-nowrap justify-center align-start">
      <div v-for="col in recallCols[page - 1]" :key="page + '-' + col.number"
           class="result-col d-flex flex-column flex-nowrap justify-start align-center"
           @mouseover="resultHighlight = col.number" @mouseleave="resultHighlight = false">
        <div v-for="word in col.words" :key="word.id"
             class="result-word d-flex flex-row flex-nowrap justify-center align-center">
          <div class="result-word-number">{{ word.number }}.</div>
          <input type="text" class="result-word-value-text-field" :id="'recall-input-' + word.id"
                 :value="col.number === resultHighlight ? memo[word.id] : recall[word.id]"
                 :class="[resultCols[word.id].class]" readonly/>
        </div>
      </div>
    </div>

    <!-- Countdown -->
    <Countdown :title="$t('events.words')" ref="countdown"/>

    <!--Mobile arrows-->
    <MobileArrows v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 780"
                  :on-left="onLeft" :on-right="onRight"/>
    <div v-if="['memo', 'recall', 'result'].includes(phase) && $vuetify.breakpoint.width <= 780"
         style="height: 60px; width: 100%"></div>

  </v-container>
</template>

<script>
import Header from '../components/HeaderTraining';
import Pagination from '../components/Pagination';
import Countdown from '../components/Countdown';
import MobileArrows from '../components/MobileArrows';

export default {
  name: 'TrainingWords',
  components: {Header, Pagination, Countdown, MobileArrows},
  data: () => ({
    page: 1, pages: 1, time: 0, score: -1, official: false, live: 0, phase: 'wait', highlight: [], input: 0,
    memo: [], recall: [], begin: 0,
    memoCols: [], recallCols: [], resultCols: [], resultHighlight: false, doubleClickMemoEnd: false
  }),
  computed: {
    params() {
      return this.$api.game.preferences;
    },
    liveTime() {
      if (this.phase === 'memo') return 0;
      else if (this.phase === 'result') return Math.min(this.time, this.params.memo * 1000);
      else return Math.max(-this.time, -this.params.memo * 1000);
    },
    onLeft() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page - 1 > 0) this.onPageChange(this.page - 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 36 >= 36 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              f.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input))
            f.focus();
            f.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        };
      } else return () => ({});
    },
    onRight() {
      if (this.phase === 'result' || this.params.highlight === 0) {
        return () => {
          if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
        };
      } else if (this.phase === 'memo') {
        return () => {
          if (this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
            this.$refs.header.reset();
          else this.doubleClickMemoEnd = this.highlight[0] + this.params.highlight >= this.params.number;
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 36 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
          setTimeout(() => document.querySelector('.highlight').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          }), 12);
        };
      } else if (this.phase === 'recall') {
        return () => {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              f.scrollIntoView({behavior: 'smooth', block: 'center'});
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
            f.scrollIntoView({behavior: 'smooth', block: 'center'});
          }
        };
      } else return () => ({});
    }
  },
  watch: {
    highlight: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    recall: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    },
    time: {
      handler() {
        this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);
      }, deep: true
    }
  },
  mounted() {
    this.pages = Math.ceil(this.params.number / 36);
    this.time = this.$api.isGameReadOnly() ? this.$api.game.time : this.$api.isGameLiveNow() ? this.$api.game.time : 0;
    this.score = this.$api.isGameReadOnly() ? this.$api.game.score : this.$api.isGameLiveNow() ? this.$api.game.score : -1;
    this.official = this.$api.game.official;
    this.live = parseInt(this.$api.game.live);
    this.phase = this.$api.isGameReadOnly() ? 'result' : 'wait';
    for (let i = 0; i < this.params.highlight; i++) this.highlight.push(i);
    this.memo = this.$api.game.data;

    if (this.$api.isGameReadOnly() || this.$api.isGameLiveNow())
      this.recall = this.$api.game.recall;
    else for (let i = 0; i < this.params.number; i++) this.recall.push('');

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 36 === 0) this.memoCols.push([]);
      if (i % 12 === 0) this.memoCols[this.memoCols.length - 1].push({number: Math.floor(i / 12) + 1, words: []});
      this.memoCols[this.memoCols.length - 1][this.memoCols[this.memoCols.length - 1].length - 1].words.push({
        id: i, value: this.memo[i], number: i + 1
      });
    }

    for (let i = 0; i < this.memo.length; i += 1) {
      if (i % 36 === 0) this.recallCols.push([]);
      if (i % 12 === 0) this.recallCols[this.recallCols.length - 1].push({number: Math.floor(i / 12) + 1, words: []});
      this.recallCols[this.recallCols.length - 1][this.recallCols[this.recallCols.length - 1].length - 1].words.push({
        id: i, number: i + 1
      });
    }

    if (!this.$api.isGameReadOnly()) {
      if (this.$api.isGameLiveNow()) {
        let now = new Date().getTime();
        if (now < this.live) {
          this.$refs.countdown.start(new Date().getTime() + this.live - now, false, this.startMemo);
        } else if (now < this.live + this.params.memo * 1000 && this.time === 0) {
          this.startMemo(this.live + this.params.memo * 1000 - now);
          this.begin = this.live;
        } else if (now < this.live + this.params.memo * 1000 + 10000) {
          this.phase = 'wait';
          this.$refs.countdown.start(this.live + this.params.memo * 1000 + 10000, false, this.startRecall);
          this.time = Math.abs(this.time);
        } else if (now < this.live + 1000 * (this.params.memo + 10 + this.params.recall) && this.time < 0) {
          this.startRecall(this.live + this.params.memo * 1000 + 10000 + this.params.recall * 1000 - now);
          this.time = Math.abs(this.time);
        } else this.stopRecall();
      } else this.$refs.countdown.start(new Date().getTime() + 10000, true, this.startMemo);
    } else this.stopRecall();

    document.addEventListener('keydown', this.eventListener);
  },
  beforeDestroy() {
    clearTimeout(this.$refs.countdown.countdown);
    clearTimeout(this.$refs.header.countdown);
    this.$api.game.gid = 'local';
    this.$api.submitGame();
    document.removeEventListener('keydown', this.eventListener);
  },
  methods: {
    clickedRecallValue(id) {
      this.input = id;
      this.setHighlight(this.input);
    },
    eventListener(e) {
      if (this.phase === 'memo') {
        if ((e.key === 'ArrowRight' || e.key === 'ArrowDown') && this.doubleClickMemoEnd && this.highlight[0] + this.params.highlight >= this.params.number)
          this.$refs.header.reset();
        else this.doubleClickMemoEnd = (e.key === 'ArrowRight' || e.key === 'ArrowDown') && this.highlight[0] + this.params.highlight >= this.params.number;

        if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
          let next = this.highlight[0] - this.params.highlight;
          if (next >= 0) {
            this.setHighlight(next);
            if (next % 36 >= 36 - this.params.highlight) this.onPageChange(this.page - 1, false);
          }
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
          let next = this.highlight[0] + this.params.highlight;
          if (next < this.params.number) {
            this.setHighlight(next);
            if (next % 36 < this.params.highlight) this.onPageChange(this.page + 1, false);
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        }
      }

      if (this.phase === 'recall') {
        if (e.key === 'ArrowLeft') {
          if (this.input < 12) return;
          this.input -= 12;
          if (this.input % 36 >= 24) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'ArrowRight') {
          if (this.input >= this.params.number - 12) return;
          this.input += 12;
          if (this.input % 36 < 12) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'ArrowUp') {
          if (this.input === 0) return;
          this.input -= 1;
          if ((this.input + 1) % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page - 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp);
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input))
            f.focus();
          }
        } else if (e.key === 'ArrowDown') {
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'Enter' && this.input !== this.params.number - 1) {
          this.input += 1;
          if (this.input % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        } else if (e.key === 'Enter') {
          this.$refs.header.reset();
        } else if (e.key === 'Tab') {
          e.preventDefault();
          if (this.input === this.params.number - 1) return;
          this.input += 1;
          if (this.input % 36 === 0) {
            let inp = this.input;
            this.onPageChange(this.page + 1, false);
            setTimeout(() => {
              this.setHighlight(inp);
              let f = document.getElementById('recall-input-' + inp)
              f.focus();
              this.input = inp;
            }, 20);
          } else {
            this.setHighlight(this.input);
            let f = document.getElementById('recall-input-' + (this.input));
            f.focus();
          }
        }
      }

      if (e.key === ',') {
        e.preventDefault();
        if (this.page - 1 >= 1) this.onPageChange(this.page - 1);
      } else if (e.key === '.') {
        e.preventDefault();
        if (this.page + 1 <= this.pages) this.onPageChange(this.page + 1);
      } else if (e.key === ' ' && ['memo', 'recall'].includes(this.phase)) {
        e.preventDefault();
        this.onPageChange(1);
      }
    },
    setHighlight(id) {
      if (this.highlight.includes(id)) return;
      let newHighlight = [];
      for (let i = 0; i < this.params.highlight; i++)
        newHighlight.push(this.params.highlight * Math.floor(id / this.params.highlight) + i);
      this.highlight = newHighlight;
    },
    startMemo(total = this.params.memo * 1000) {
      this.phase = 'memo';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopMemo);
      this.begin = new Date().getTime();
    },
    startRecall(total = this.params.recall * 1000) {
      this.onPageChange(1);
      this.input = 0;
      this.setHighlight(0);
      this.phase = 'recall';
      this.$refs.header.start(new Date().getTime() + total, true, this.stopRecall);
    },
    stopMemo() {
      this.phase = 'wait';
      let delay = this.live === 0 ? 10000 : this.live + 10000 + this.params.memo * 1000 - new Date().getTime();
      this.$refs.countdown.start(new Date().getTime() + delay, this.live === 0, this.startRecall);
      this.time = Math.min(Math.round((new Date().getTime() - this.begin) / 10), this.params.memo * 100);
    },
    stopRecall() {
      this.phase = 'result';
      let score = 0;
      for (let i = 0; i < this.memo.length; i++) {
        if (this.$api.wordsMatch(this.recall[i], this.memo[i])) score++;
        if (this.$api.wordsMatch(this.recall[i], this.memo[i]))
          this.resultCols.push({class: 'result-correct'});
        else if (this.recall[i] === '')
          this.resultCols.push({class: 'result-empty'});
        else this.resultCols.push({class: 'result-incorrect'});
      }
      this.score = score;

      this.$api.liveSet(this.$api.game.gid, this.highlight, this.liveTime, this.recall);

      this.$refs.header.setScore(this.score, Math.min(this.time, 100 * this.params.memo));
      if (!this.$api.isGameReadOnly()) this.$api.submitGame(this.time, this.recall).then(() => {
        this.$store.commit('setMyResults', []);
        this.$api.init().then(() => {
          this.$store.commit('setProfile', this.$api.profile);
          let bestScore = this.$store.state.profile.events['words'].score;
          let bestTime = this.$store.state.profile.events['words'].time;
          if (this.official && this.score === bestScore && this.time === bestTime) this.$store.commit('showAdvancedDialog', {
            title: this.$t('dialogs.personal-best-title'), sections: [{
              title: this.$t('dialogs.personal-best-section-title'),
              icon: 'mdi-trophy-award',
              content: this.$t('dialogs.personal-best-section-content')
            }]
          });
        });
      });
    },
    onPageChange(page, hard = true) {
      this.page = page;
      window.scrollTo({top: 0, behavior: 'auto'});
      setTimeout(() => {
        if (this.phase === 'memo') {
          if (hard) this.setHighlight((page - 1) * 36);
        } else if (this.phase === 'recall') {
          this.input = (page - 1) * 36;
          this.setHighlight(this.input);
          let f = document.getElementById('recall-input-' + (this.input));
          f.focus();
        }
      }, 10);
    }
  }
};
</script>

<style scoped>
#memo {
  margin: 0 auto;
  width: 750px;
}

.memo-col {
  width: 250px;
}

.memo-word {
  height: 34px;
}

.memo-word-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 10px;
  text-align: right;
  width: 50px;
}

.memo-word-value {
  font-size: 21px;
  height: 34px;
  padding: 0 6px;
  text-align: left;
  width: 200px;
}

#recall {
  margin: 0 auto;
  width: 810px;
}

.recall-col {
  width: 270px;
}

.recall-word {
  height: 40px;
  padding-right: 5px;
  width: 270px;
}

.recall-word-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 5px;
  text-align: right;
  width: 60px;
}

.recall-word-value-text-field {
  background-color: #ffffff;
  border: 2px solid #336799;
  border-radius: 8px;
  font-size: 19px;
  height: 34px;
  padding: 0 6px;
  width: 200px;
}

#result {
  margin: 0 auto;
  width: 810px;
}

.result-col {
  width: 270px;
}

.result-word {
  height: 40px;
  padding-right: 5px;
  width: 265px;
}

.result-word-number {
  color: #336799;
  font-size: 22px;
  font-weight: 700;
  margin-right: 5px;
  text-align: right;
  width: 60px;
}

.result-word-value-text-field {
  background-color: #ffffff;
  border: 2px solid #336799;
  border-radius: 8px;
  font-size: 19px;
  height: 34px;
  padding: 0 6px;
  width: 200px;
}

input:focus {
  outline: none;
}

.result-correct {
  border: 2px solid #4CAF50;
}

.result-incorrect {
  border: 2px solid #E53935;
}

.result-empty {
  border: 2px solid #336799;
}

.result-col:hover .result-incorrect, .result-col:hover .result-empty {
  border: 2px dotted #4CAF50;
}

@media screen and (max-width: 780px) {
  #memo, #recall, #result {
    flex-direction: column !important;
    width: 100% !important;
  }

  .memo-col, .recall-col, .result-col {
    margin: 0 auto;
  }
}
</style>
